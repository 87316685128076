const CHARADE_LIST = [
  { name: "Jingle Bells", theme: "festive", cat: "song" },
  { name: "Fairytale of New York", theme: "festive", cat: "song" },
  { name: "Last Christmas", theme: "festive", cat: "song" },
  { name: "Let There Be Peace on Earth", theme: "festive", cat: "song" },
  { name: "The Holly and the Ivy", theme: "festive", cat: "song" },
  { name: "Rudolph the Red-Nosed Reindeer", theme: "festive", cat: "song" },
  { name: "The Little Drummer Boy", theme: "festive", cat: "song" },
  { name: "Go Tell It on the Mountain", theme: "festive", cat: "song" },
  { name: "Hark! The Herald Angels Sing", theme: "festive", cat: "song" },
  { name: "Lonely Pup in a Christmas Shop", theme: "festive", cat: "song" },
  { name: "Deck the Halls", theme: "festive", cat: "song" },
  { name: "Blue Christmas", theme: "festive", cat: "song" },
  { name: "Jingle Bell Rock", theme: "festive", cat: "song" },
  { name: "Silent Night", theme: "festive", cat: "song" },
  {
    name: "I Want a Hippopotamus for Christmas",
    theme: "festive",
    cat: "song",
  },
  {
    name: "All I Want for Christmas Is My Two Front Teeth",
    theme: "festive",
    cat: "song",
  },
  { name: "Ding Dong Merrily on High", theme: "festive", cat: "song" },
  { name: "I Saw Mommy Kissing Santa Claus", theme: "festive", cat: "song" },
  { name: "Santa Baby", theme: "festive", cat: "song" },
  { name: "Santa Claus Is Coming to Town", theme: "festive", cat: "song" },
  { name: "It Came Upon a Midnight Clear", theme: "festive", cat: "song" },
  { name: "Here Comes Santa Claus", theme: "festive", cat: "song" },
  { name: "Winter Wonderland", theme: "festive", cat: "song" },
  { name: "Snow In California", theme: "festive", cat: "song" },
  { name: "The Man with the Bag", theme: "festive", cat: "song" },
  { name: "Under the Mistletoe", theme: "festive", cat: "song" },
  { name: "The Christmas Song", theme: "festive", cat: "song" },
  { name: "I Saw Three Ships", theme: "festive", cat: "song" },
  { name: "Up on the Housetop", theme: "festive", cat: "song" },
  { name: "Walking in the Air", theme: "festive", cat: "song" },
  { name: "Marshmallow World", theme: "festive", cat: "song" },
  { name: "Run, Rudolph, Run", theme: "festive", cat: "song" },
  { name: "Sleigh Ride", theme: "festive", cat: "song" },
  { name: "God Rest Ye Merry Gentlemen", theme: "festive", cat: "song" },
  { name: "O Come, All Ye Faithful", theme: "festive", cat: "song" },
  {
    name: "It's the Most Wonderful Time of the Year",
    theme: "festive",
    cat: "song",
  },
  { name: "We Wish You a Merry Christmas", theme: "festive", cat: "song" },
  { name: "Joy to the World", theme: "festive", cat: "song" },
  { name: "Carol of the Bells", theme: "festive", cat: "song" },
  { name: "Step into Christmas", theme: "festive", cat: "song" },
  { name: "Grandma Got Run Over by a Reindeer", theme: "festive", cat: "song" },
  {
    name: "It's Beginning to Look a Lot Like Christmas",
    theme: "festive",
    cat: "song",
  },
  { name: "Jingle Bells", theme: "festive", cat: "song" },
  { name: "Santa's Got a Bag of Soul", theme: "festive", cat: "song" },
  { name: "All I Want for Christmas is You", theme: "festive", cat: "song" },
  { name: "We Three Kings", theme: "festive", cat: "song" },
  { name: "Baby, It's Cold Outside", theme: "festive", cat: "song" },
  { name: "Let it Snow", theme: "festive", cat: "song" },
  { name: "Rockin' Around the Christmas Tree", theme: "festive", cat: "song" },
  { name: "Do You Hear What I Hear?", theme: "festive", cat: "song" },
  { name: "Dance of the Sugar Plum Fairy", theme: "festive", cat: "song" },
  { name: "Christmas Wrapping", theme: "festive", cat: "song" },
  { name: "A Christmas Prince", theme: "festive", cat: "film" },
  { name: "A Christmas Prince: Royal Wedding", theme: "festive", cat: "film" },
  { name: "A Christmas Prince: The Royal Baby", theme: "festive", cat: "film" },
  { name: "The Princess Switch", theme: "festive", cat: "film" },
  {
    name: "The Princess Switch: Switched Again",
    theme: "festive",
    cat: "film",
  },
  { name: "Klaus", theme: "festive", cat: "film" },
  { name: "Arthur Christmas", theme: "festive", cat: "film" },
  { name: "Four Holidays", theme: "festive", cat: "film" },
  { name: "The Christmas Toy", theme: "festive", cat: "film" },
  { name: "The Holiday", theme: "festive", cat: "film" },
  { name: "The Santa Suit", theme: "festive", cat: "film" },
  { name: "A Christmas Carol", theme: "festive", cat: "film" },
  { name: "Die Hard", theme: "festive", cat: "film" },
  { name: "Silent Night, Deadly Night", theme: "festive", cat: "film" },
  { name: "Bad Santa", theme: "festive", cat: "film" },
  { name: "It's a Wonderful Life", theme: "festive", cat: "film" },
  { name: "A Boyfriend for Christmas", theme: "festive", cat: "film" },
  { name: "Silent Night, Lonely Night", theme: "festive", cat: "film" },
  { name: "Holiday Inn", theme: "festive", cat: "film" },
  { name: "A Smokey Mountain Christmas", theme: "festive", cat: "film" },
  { name: "A Mom for Christmas", theme: "festive", cat: "film" },
  { name: "The Polar Express", theme: "festive", cat: "film" },
  { name: "SnowGlobe", theme: "festive", cat: "film" },
  { name: "Santa Clause Conquers the Martians", theme: "festive", cat: "film" },
  { name: "Home Alone", theme: "festive", cat: "film" },
  { name: "I'll Be Home for Christmas", theme: "festive", cat: "film" },
  { name: "The Year Without a Santa Clause", theme: "festive", cat: "film" },
  { name: "Olive, the Other Reindeer", theme: "festive", cat: "film" },
  { name: "A Christmas Without Snow", theme: "festive", cat: "film" },
  { name: "Jingle All the Way", theme: "festive", cat: "film" },
  { name: "Krampus", theme: "festive", cat: "film" },
  { name: "Christmas with the Kranks", theme: "festive", cat: "film" },
  { name: "Recipe for a Perfect Christmas", theme: "festive", cat: "film" },
  { name: "Reindeer Games", theme: "festive", cat: "film" },
  {
    name: "Nation Lampoon's Christmas Vacation",
    theme: "festive",
    cat: "film",
  },
  { name: "Edward Scissorhands", theme: "festive", cat: "film" },
  { name: "Miracle on 34th Street", theme: "festive", cat: "film" },
  { name: "Holiday in Handcuffs", theme: "festive", cat: "film" },
  { name: "The 12 Dogs of Christmas", theme: "festive", cat: "film" },
  { name: "Santa with Muscles", theme: "festive", cat: "film" },
  { name: "The Search for Santa Paws", theme: "festive", cat: "film" },
  { name: "Trading Places", theme: "festive", cat: "film" },
  { name: "Jack Frost", theme: "festive", cat: "film" },
  { name: "The Nutcracker", theme: "festive", cat: "film" },
  { name: "Santa Who?", theme: "festive", cat: "film" },
  { name: "Frozen", theme: "festive", cat: "film" },
  { name: "Babes in Toyland", theme: "festive", cat: "film" },
  { name: "How the Grinch Stole Christmas", theme: "festive", cat: "film" },
  { name: "The Nightmare Before Christmas", theme: "festive", cat: "film" },
  { name: "Love Actually", theme: "festive", cat: "film" },
  { name: "Rise of the Guardians", theme: "festive", cat: "film" },
  { name: "A Christmas to Remember", theme: "festive", cat: "film" },
  {
    name: "The Bear Who Slept Through Christmas",
    theme: "festive",
    cat: "film",
  },
  { name: "The Man Who Invented Christmas", theme: "festive", cat: "film" },
  { name: "Elf", theme: "festive", cat: "film" },
  { name: "A Trap for Santa Claus", theme: "festive", cat: "film" },
  { name: "The House Without a Christmas Tree", theme: "festive", cat: "film" },
  { name: "Four Holidays", theme: "festive", cat: "film" },
  {
    name: "Shaking a present to try to guess what's inside",
    theme: "festive",
    cat: "action",
  },
  { name: "Making a snow angel", theme: "festive", cat: "action" },
  { name: "Baking Christmas cookies", theme: "festive", cat: "action" },
  { name: "Having a snowball fight", theme: "festive", cat: "action" },
  {
    name: "Sitting on Father Christmas' knee",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Rudolph's red nose shining bright",
    theme: "festive",
    cat: "action",
  },
  { name: "Going sledging", theme: "festive", cat: "action" },
  { name: "Going skiing", theme: "festive", cat: "action" },
  { name: "Going caroling", theme: "festive", cat: "action" },
  {
    name: "Finding a lump of coal in your stocking",
    theme: "festive",
    cat: "action",
  },
  { name: "Making a paper snowflake", theme: "festive", cat: "action" },
  {
    name: "Pulling Father Christmas' beard to see if it's real",
    theme: "festive",
    cat: "action",
  },
  { name: "A snowman melting away", theme: "festive", cat: "action" },
  { name: "Stuffing a turkey", theme: "festive", cat: "action" },
  { name: "Hanging up mistletoe", theme: "festive", cat: "action" },
  { name: "Wearing an ugly Christmas jumper", theme: "festive", cat: "action" },
  { name: "Reading out cheesy cracker jokes", theme: "festive", cat: "action" },
  { name: "Building an igloo", theme: "festive", cat: "action" },
  {
    name: "Leaving out a carrot for Father Christmas' reindeer",
    theme: "festive",
    cat: "action",
  },
  { name: "Roasting chestnuts on a fire", theme: "festive", cat: "action" },
  { name: "Pulling a Christmas cracker", theme: "festive", cat: "action" },
  {
    name: "Joining the crowds for the Boxing Day sales",
    theme: "festive",
    cat: "action",
  },
  { name: "Going snowboarding", theme: "festive", cat: "action" },
  {
    name: '"Not a creature was stirring, not even a mouse"',
    theme: "festive",
    cat: "action",
  },
  {
    name: "Peeling potatoes for Christmas dinner",
    theme: "festive",
    cat: "action",
  },
  { name: "Drinking mulled wine", theme: "festive", cat: "action" },
  { name: "Decorating a gingerbread house", theme: "festive", cat: "action" },
  {
    name: "Waking up to see that it's snowed",
    theme: "festive",
    cat: "action",
  },
  { name: "Knitting a scarf", theme: "festive", cat: "action" },
  {
    name: "Catching a snowflake on your tongue",
    theme: "festive",
    cat: "action",
  },
  { name: "Performing the Nutcracker ballet", theme: "festive", cat: "action" },
  {
    name: "Trying to find out where the Christmas presents are hidden",
    theme: "festive",
    cat: "action",
  },
  { name: "Eating a candy cane", theme: "festive", cat: "action" },
  {
    name: "Putting the star on top of the tree",
    theme: "festive",
    cat: "action",
  },
  { name: "Shaking up a snowglobe", theme: "festive", cat: "action" },
  {
    name: "Father Christmas climbing down the chimney",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Waking up your parents early on Christmas morning",
    theme: "festive",
    cat: "action",
  },
  { name: "Waddling like a penguin", theme: "festive", cat: "action" },
  { name: "Chopping down a Christmas tree", theme: "festive", cat: "action" },
  { name: "Eating too many Brussels sprouts", theme: "festive", cat: "action" },
  { name: "Wrapping Christmas presents", theme: "festive", cat: "action" },
  { name: "Writing Christmas cards", theme: "festive", cat: "action" },
  {
    name: "Walking outside and trying not to slip on the ice",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Trying to untangle the string of Christmas lights",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Lighting the brandy on a Christmas pudding",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Shopping for a last minute Christmas gift",
    theme: "festive",
    cat: "action",
  },
  { name: "Going to watch pantomime", theme: "festive", cat: "action" },
  {
    name: "Taking a cheesy Christmas card photo",
    theme: "festive",
    cat: "action",
  },
  {
    name: "Scraping the ice off a car window",
    theme: "festive",
    cat: "action",
  },
  { name: "Hanging up a Christmas wreath", theme: "festive", cat: "action" },
  { name: "Mrs Claus", theme: "festive", cat: "misc" },
  { name: "A partridge in a pear tree", theme: "festive", cat: "misc" },
  { name: "Sugar plum fairy", theme: "festive", cat: "misc" },
  { name: "Snowman", theme: "festive", cat: "misc" },
  { name: "Four calling birds", theme: "festive", cat: "misc" },
  { name: "Frostbite", theme: "festive", cat: "misc" },
  { name: "New Year", theme: "festive", cat: "misc" },
  { name: "Icicle", theme: "festive", cat: "misc" },
  { name: "Frosty the Snowman", theme: "festive", cat: "misc" },
  { name: "Polar bear", theme: "festive", cat: "misc" },
  { name: "Eggnong", theme: "festive", cat: "misc" },
  { name: "The Royal Christmas Message", theme: "festive", cat: "misc" },
  { name: "Pine cone", theme: "festive", cat: "misc" },
  { name: "Fruit cake", theme: "festive", cat: "misc" },
  { name: "Naughty or nice list", theme: "festive", cat: "misc" },
  { name: "Sleigh bells", theme: "festive", cat: "misc" },
  { name: "Gingerbread man", theme: "festive", cat: "misc" },
  { name: "Elf on the shelf", theme: "festive", cat: "misc" },
  { name: "Comet", theme: "festive", cat: "misc" },
  { name: "Humbug", theme: "festive", cat: "misc" },
  { name: "Carol singers", theme: "festive", cat: "misc" },
  { name: "Lapland", theme: "festive", cat: "misc" },
  { name: "Jack Frost", theme: "festive", cat: "misc" },
  { name: "Season's greetings", theme: "festive", cat: "misc" },
  { name: "Tiny Tim", theme: "festive", cat: "misc" },
  { name: "Hot Chocolate", theme: "festive", cat: "misc" },
  { name: "Dasher", theme: "festive", cat: "misc" },
  { name: "Prancer", theme: "festive", cat: "misc" },
  { name: "Stocking filler", theme: "festive", cat: "misc" },
  { name: "Three French hens", theme: "festive", cat: "misc" },
  { name: "Stuffing", theme: "festive", cat: "misc" },
  { name: "Yule log", theme: "festive", cat: "misc" },
  { name: "Mince pie", theme: "festive", cat: "misc" },
  { name: "Mistletoe", theme: "festive", cat: "misc" },
  { name: "Santa hat", theme: "festive", cat: "misc" },
  { name: "The Ghost of Christmas Past", theme: "festive", cat: "misc" },
  { name: "The Ghost of Christmas Present", theme: "festive", cat: "misc" },
  { name: "The Ghost of Christmas Yet to Come", theme: "festive", cat: "misc" },
  { name: "Snowflake", theme: "festive", cat: "misc" },
  { name: "Tinsel", theme: "festive", cat: "misc" },
  { name: "North Pole", theme: "festive", cat: "misc" },
  { name: "Frankincense", theme: "festive", cat: "misc" },
  { name: "Fairy lights", theme: "festive", cat: "misc" },
  { name: "Blizzard", theme: "festive", cat: "misc" },
  { name: "Wrapping paper", theme: "festive", cat: "misc" },
  { name: "Fruitcake", theme: "festive", cat: "misc" },
  { name: "Boxing Day", theme: "festive", cat: "misc" },
  { name: "Pigs in blankets", theme: "festive", cat: "misc" },
  { name: "Snowball", theme: "festive", cat: "misc" },
  { name: "Secret Santa", theme: "festive", cat: "misc" },
  { name: "Chimney", theme: "festive", cat: "misc" },
  { name: "Antlers", theme: "festive", cat: "misc" },
];

export default CHARADE_LIST;
